<template>
  <div
    :class="{
      'chat-overlay': true,
      dragging: is_dragging,
      maximize: maximize && visible,
    }"
    ref="overlay"
  >
    <transition name="fade">
      <vue-resizable
        v-if="mounted"
        v-show="visible"
        class="resizable"
        ref="resizableComponent"
        :dragSelector="dragSelector"
        :active="handlers"
        :fit-parent="fit"
        :maximize="maximize"
        :max-width="checkEmpty(maxW)"
        :max-height="checkEmpty(maxH)"
        :min-width="checkEmpty(minW)"
        :min-height="checkEmpty(minH)"
        :width="width"
        :height="height"
        :left="left"
        :top="top"
        @mount="eHandler"
        @resize:move="eHandler"
        @resize:start="eHandler"
        @resize:end="eHandler"
        @drag:move="eHandler"
        @drag:start="eHandler"
        @drag:end="eHandler"
        @maximize="eHandler"
      >
        <div :class="{ 'chat-container': true, maximize: maximize && visible }">
          <div class="chat-header prevent-select">
            <div class="drag chat-title">ILUKS chatbot</div>

            <div class="controls">
              <v-btn
                @click="_minimize()"
                small
                icon
                class="mx-2"
                fab
                color="primary"
                ><v-icon dark>mdi-window-minimize</v-icon></v-btn
              >
              <v-btn
                small
                icon
                class="mx-2"
                fab
                color="primary"
                @click="_maximize()"
              >
                <v-icon dark v-if="!maximize">mdi-window-maximize</v-icon>
                <v-icon dark v-if="maximize">mdi-window-restore</v-icon>
              </v-btn>
            </div>
          </div>
          <div :class="{ content: true, dragging: is_dragging }">
            <iframe
              ref="iframe"
              :src="`/chat/?locale=${$i18n.locale}`"
              style="
                position: absolute;
                top: 20px;
                left: 20px;
                bottom: 20px;
                right: 20px;
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                border: none;
                margin: 0;
                padding: 0;
                overflow: hidden;
                z-index: 100;
              "
            >
              Your browser doesn't support iframes
            </iframe>
            <div class="container-mask"></div>
          </div>
        </div>
      </vue-resizable>
    </transition>

    <transition name="fade">
      <div
        class="chat-button prevent-select"
        @click="display"
        v-show="chatButtonIsVisible"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
          <!-- Circular background with gradient -->
          <defs>
            <linearGradient
              id="bg-gradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop offset="0%" stop-color="#4158D0" />
              <stop offset="46%" stop-color="#C850C0" />
              <stop offset="100%" stop-color="#FFCC70" />
            </linearGradient>

            <clipPath id="insideCircleOnly">
              <circle cx="100" cy="100" r="100" />
            </clipPath>
          </defs>

          <!-- Circle background -->
          <circle
            cx="100"
            cy="100"
            r="100"
            fill="url(#bg-gradient)"
            stroke="white"
            stroke-width="20"
            clip-path="url(#insideCircleOnly)"
          />

          <!-- Text elements - compacted to fit circular shape -->
          <text
            x="100"
            y="90"
            font-family="Roboto, sans-serif"
            font-size="46"
            font-weight="bold"
            fill="white"
            text-anchor="middle"
          >
            ILUKS
          </text>
          <text
            x="100"
            y="130"
            font-family="Roboto, sans-serif"
            font-size="42"
            fill="white"
            text-anchor="middle"
          >
            chatbot
          </text>
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>
import VueResizable from "./VueResizeable.vue";

export default {
  name: "App",
  components: { VueResizable },
  overlay: null,
  data() {
    const tH = 150;
    return {
      is_dragging: false,
      chatButtonIsVisible: true,
      visible: false,
      mounted: false,
      handlers: ["r", "rb", "b", "lb", "l", "lt", "t", "rt"],
      left: `50vw`,
      top: `calc(50% - ${tH / 2}px)`,
      height: -1,
      width: -1,
      maxW: -1,
      maxH: -1,
      minW: 100,
      minH: 100,
      fit: true,
      maximize: false,
      event: "",
      dragSelector: [".drag", ".header"].join(", "),
    };
  },
  mounted() {
    this.overlay = this.$refs.overlay;

    this.checkEnv();

    this.mounted = true;

    window.addEventListener("resize", () => {
      this.checkEnv();
    });
  },
  methods: {
    needMaximize() {
      if (this.overlay.clientWidth <= 412 && this.overlay.clientHeight <= 915)
        return true;
      return false;
      /**
        Desktop: 1920×1080, 1366×768, 1536×864, 1280×720
        Mobile: 360×800, 390×844, 393×873, 412×915
        Tablet: 768×1024, 1280×800, 800×1280, 820×1180
      **/
    },
    checkEnv() {
      if (this.needMaximize()) this.maximize = true;

      this.maxW = this.overlay.clientWidth;
      this.maxH = this.overlay.clientHeight;

      if (this.maximize) {
        this.left = 0;
        this.top = 0;
        this.width = this.maxW;
        this.height = this.maxH;
      }
      if (!this.mounted) {
        this.left = this.maxW / 2;
        this.top = this.maxH * 0.25;
        this.width = this.maxW - this.left;
        this.height = this.maxH - this.top;
      }

      if (this.left + this.width > this.maxW)
        this.left = this.maxW - this.width;
      if (this.top + this.height > this.maxH)
        this.top = this.maxH - this.height;

      if (this.left < 0) this.left = 0;
      if (this.top < 0) this.top = 0;

      this.width = Math.min(this.width, this.maxW);
      this.height = Math.min(this.height, this.maxH);
    },
    display() {
      this.chatButtonIsVisible = false;
      setTimeout(() => {
        this.visible = true;
        this.checkEnv();
      }, 500);
    },
    _maximize() {
      this.maximize = !this.maximize;
    },
    _minimize() {
      this.visible = false;
      this.chatButtonIsVisible = true;
    },
    eHandler(data) {
      this.width = data.width;
      this.height = data.height;
      this.left = data.left;
      this.top = data.top;
      this.event = data.eventName;
      if (data.eventName === "maximize") {
        this.maximize = data.state;
      }

      this.checkEnv();
      if (["mount", "maximize"].indexOf(data.eventName) !== -1) return;

      this.is_dragging = data.eventName.indexOf("end") === -1 ? true : false;
    },
    checkEmpty(value) {
      return typeof value !== "number" ? 0 : value;
    },
  },
  filters: {
    checkEmpty(value) {
      return typeof value !== "number" ? 0 : value;
    },
  },
};
</script>

<style scoped>
.no-pointer-events {
  pointer-events: none !important;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.chat-overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10000;
  pointer-events: none;
}
.chat-overlay.dragging {
  pointer-events: all;
  -background-color: rgba(0, 0, 0, 0.1);
}
.chat-overlay.maximize {
  background-color: #fff;
}

.chat-button {
  pointer-events: all;
  width: 60px;
  height: 60px;
  position: absolute;
  right: 18.5px;
  bottom: 18.5px;
  z-index: 4;
  cursor: pointer;
}
.chat-button:hover {
  scale: 1.1;
}

.chat-container {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid rgba(125, 125, 125, 0.2);
  overflow: hidden;

  /**
    border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; **/
  /** box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; **/
}
.chat-container.maximize {
  border: none;
}

.chat-header {
  top: 0px;
  right: 0px;
  left: 0px;
  height: 48px;
  position: absolute;
  display: flex;
}

.chat-title {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.controls {
  display: flex;
  justify-self: flex-end;
  justify-content: center;
  align-items: center;
}

.content {
  position: absolute;
  top: 48px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #fff;
  overflow: hidden;
  border-top: 2px solid rgba(125, 125, 125, 0.2);
}
.container-mask {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  z-index: 101;
  pointer-events: none;
}
.dragging .container-mask {
  pointer-events: all;
}

.resizable {
  padding: 0;
  position: relative;
  pointer-events: all;
}
</style>
