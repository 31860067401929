/* eslint-disable no-unused-vars */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import { io, Manager } from "socket.io-client";

const _$FG = {};

try {
  Object.assign(_$FG, {
    ...Object.entries(process.env).reduce((acc, entry) => {
      if (entry[0].indexOf("VUE_APP_FG_") === 0) {
        const key = entry[0].split("VUE_APP_FG_")[1].trim();
        if (key.length > 0) {
          let value =
            entry[1] === "yes" ? true : entry[1] === "no" ? false : entry[1];
          if (value !== true && value !== false) {
            value = value.trim();
            if (value.length > 0) acc[key] = value;
          } else {
            acc[key] = value;
          }
        }
      }
      return acc;
    }, {}),
    courses: process.env.VUE_APP_COURSES === "yes",
  });
  Vue.prototype.$fg = _$FG;
} catch (error) {
  error;
  Vue.prototype.$fg = {};
}

/**
if (Function.prototype.bind) {
  window.log = Function.prototype.bind.call(console.log, console);
} else {
  window.log = function () {
    Function.prototype.apply.call(console.log, console, arguments);
  };
}
console.log = function () {
  if (process.env.NODE_ENV === "development" || _$FG.CONSOLE_LOG_ENABLED) {
    window.log(...arguments);
  }
}; **/

// import MediumEditor from "vuejs-medium-editor";

import axios from "axios";

import translations from "./store/translations";

const edjsHTML = require("editorjs-html");
Vue.prototype.$edjsParser = edjsHTML();

Vue.config.productionTip = false;

Vue.appIsLoaded = false;

Vue.prototype.$ph = (promise) => {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => Promise.resolve([undefined, error]));
};

Vue.use(VueI18n);
const seachParams = new URLSearchParams(window.location.search);
const langParam = localStorage.getItem("lang");
const lang = ["en", "no"].indexOf(langParam) > -1 ? langParam : "no";
localStorage.setItem("lang", lang);
const i18n = new VueI18n({
  locale: lang, // set locale
  messages: translations, // set locale messages
});

Vue.prototype.$http = axios;

const MAIN_HOST = (() => {
  let tmp = window.location.host;
  tmp = tmp.slice(tmp.indexOf(".") + 1);
  return tmp;
})();

let isFirstLogin = true;

Vue.prototype.$checkAuth = async ({ to, from, next }) => {
  try {
    /**
  const check = await Vue.prototype.$ph(
    Vue.prototype.$http(Vue.prototype.API_BASE_URL + "/users/@me", {
      withCredentials: true,
    })
  );
  const data = check?.[0]?.data;
  console.log(check);
  // const response = check[0] || check[1].response;
  // if (response?.data?.statusCode === 200) {
  if (typeof check[1] === "undefined" && !data.error) { **/
    await store.dispatch("checkAuth");
    if (store.state?.isAuth) {
      if (to.name === "Home") {
        next({ name: "Projects" });
      } else {
        if (isFirstLogin) {
          isFirstLogin = false;
          /**
        const wsURL = `${Vue.prototype.API_HOST}/v2/ws`;
        const manager = new Manager(wsURL, {
          multiplex: true,
          path: "/v2/ws",
          withCredentials: true,
        });
        const notifications = manager.socket("/v2/ws/fragments");
        notifications.onAny((event, ...args) => {
          console.log(event, args);
        });
        const socket = manager.socket("/v2/ws/fragments");
        socket.on("connect", () => {
          // Server Internal Error if emit an empty message
          socket.emit("subscribe-fragment", {
            fragmentId: "25ae5670-506a-42fa-86de-a7dd54ccebea",
          });
        });
        socket.on("disconnect", () => {
          console.log("disconnected");
        });
        socket.onAny((event, ...args) => {
          console.log(event, args);
        }); **/
        }
        next();
      }
    } else {
      // location.replace(Vue.prototype.API_HOST + "/logout");
      if (to.name !== "Home") {
        next({ name: "Home", query: null });
      } else {
        next({ query: null });
      }
    }
    /**  }  else {
    // TODO: Check ping and logout redirect
    if (check[1]?.response?.data?.uuid) {
      location.replace(Vue.prototype.API_HOST + "/logout");
    } else {
      if (to.name !== "Home") {
        next({ name: "Home", query: null });
      } else {
        next({ query: null });
      }
    }
    if (to.name !== "Home") {
      next({ name: "Home", query: null });
    } else {
      next({ query: null });
    }
  } **/
  } catch (error) {
    // console.log(error);
  }
};

Vue.prototype.API_HOST = process.env.VUE_APP_API_HOST; // window.location.protocol + "//api." + MAIN_HOST;
Vue.prototype.API_BASE_URL = Vue.prototype.API_HOST + "/v2";

Vue.prototype.$api = axios.create({
  baseURL: Vue.prototype.API_BASE_URL,
  withCredentials: true,
});

// Vue.component("medium-editor", MediumEditor);

store.$i18n = i18n;

Vue.prototype.$print_role = (role) => i18n.t(`roles.${role}`);

const DATETIME_FORMAT = [
  [],
  {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  },
];

Vue.prototype.$date = (date) => {
  return new Date(date).toLocaleString(...DATETIME_FORMAT);
};

const app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.$eventTracking = (category, action, paramsList = []) => {
  const user = app.$store.state.user;

  const params = [["user", user.id], ...paramsList];

  const queryParams = params
    .map((p) => {
      return `${p[0]}=${encodeURIComponent(p[1])}`;
    })
    .join("&");

  const event = ["trackEvent", category, action, queryParams];

  // console.log(event);
  window._paq.push(event);
};
