/* eslint-disable no-unused-vars */
import Vue from "vue";
import router from "../../router/index";

const TMS = {};

const PRESETS_BLANK = require("../presets_blank.json");

async function createProject({ state, commit }, { type, courseId }) {
  const projectName =
    this.$i18n.t("learningDesign.newLearningDesign") +
    "#" +
    (state.data.projects.length + 1);
  const prerequisites = this.$i18n.t("columns.trinn.newCard");

  const result = await Vue.prototype.$api.post("/fragments/@me", {
    data: {
      name: projectName,
      description: "[description]",
      tags: [],
    },
    // courseId: courseId || null,
    // data,
    // extras: { title: data.title },
  });
  const project = result?.data?.result?.fragments?.root?.[0] || false;
  if (project) {
    const preset = PRESETS_BLANK.lists.map((e) => ({
      id: e.id,
      type: e.type,
      title: e.title,
      fixed: e.fixed || false,
    }));

    const url = `/fragments/${project.id}/columns`;

    let defaultCard = false;

    for (let i = 0; i < preset.length; i++) {
      const col = await Vue.prototype.$api.post(url, {
        parentId: project.id,
        extras: {
          id: preset[i].id,
          type: preset[i].type,
          fixed: preset[i].fixed || false,
        },
        column: {
          name: preset[i].title,
          index: i * 100 + 100,
          tags: [],
          attachments: [],
        },
      });
      const fragment = col?.data?.fragments?.column?.[0] || false;
      if (fragment && fragment?.extras?.fixed && fragment?.extras?.type) {
        await this.dispatch("createCard", {
          projectId: project.id,
          parentId: fragment.id,
          title: prerequisites,
          type: "trinn",
          index: 1,
          fixed: true,
          callback: async ({ doc_id, card_id }) => {
            await this.dispatch("createCard", {
              projectId: project.id,
              parentId: fragment.id,
              title: projectName,
              type: fragment?.extras?.type,
              index: 0,
              fixed: true,
              callback: ({ doc_id, card_id }) => {
                if (doc_id && card_id) {
                  setTimeout(() => {
                    router.push({
                      name: "Object",
                      params: { item: card_id, id: doc_id },
                    });
                  }, 1000);
                }
              },
            });
          },
        });
      }
    }
  }
}

async function getProject({ commit }, req) {
  let id = req;
  if (req?.doc_id) id = req.doc_id;

  const result = await Vue.prototype.$api.get("/fragments/" + id);
  const doc = result?.data?.result?.fragments;

  if (!doc) return;

  const access = await Vue.prototype.$api.get("/fragments/" + id + "/access");
  doc.users = (access?.data?.result || []).filter(
    (e) => e?.status !== "rejected"
  );

  if (!req?.callback) {
    commit("getProject", doc);
  } else {
    commit("getProject", { doc, callback: req.callback });
  }
}

// TODO: Is /fragments/@me display only created by me or shared with me or if I course admin - all root
// TODO: How get all projects for the course owner
// TODO: Error message: 'page number must be a valid integer > 0'
async function getProjects({ state, commit }, courseId) {
  const params = {};
  if ((state.user.roles || []).indexOf("course_owner") !== -1)
    params.query_mode = "all";
  params.page_number = 1;
  params.page_size = 1000;

  const result = await Vue.prototype.$api.get("/fragments/@me", {
    params,
  });
  const docs = result?.data?.result?.fragments?.root || [];
  docs.forEach((doc) => {
    if (!doc.users) {
      doc.users = [];
      doc.users.push({
        id: state.user.id,
        role: "owner",
      });
    }
  });

  // TODO: Get amount of projects
  state.data.projectsCount = docs?.length || 0;
  if (courseId) {
    result.data.result = result.data.result.fragments.filter(
      (e) => e.courseId === courseId
    );
  }

  commit("getProjects", {
    data: {
      result: docs,
    },
  });
}

async function updateProject(
  { commit, state },
  { _id, data, extras, delay, action }
) {
  return new Promise(function (resolve, reject) {
    if (TMS[_id]) {
      clearTimeout(TMS[_id]);
    }
    TMS[_id] = setTimeout(
      async () => {
        /**
        const result = await Vue.prototype.$api.post("/containers/" + _id, {
          data,
          extras,
        }); **/
        // resolve(result);
        resolve({});
      },
      delay ? 750 : 0
    );
  });
}

async function deleteProject({ commit }, doc) {
  if (typeof doc === "string" || doc?.id) {
    const doc_id = doc?.id || doc;
    const url = `/fragments/${doc_id}/archive`;
    const res = await Vue.prototype.$api.patch(url, {
      fragmentId: doc_id,
    });

    // console.log(res?.data, doc_id);

    if (!doc.callback) {
      this.dispatch("getProjects");
    } else {
      doc.callback();
    }
  }
}

async function renameProject({ dispatch, state, comit }, { id, title, tags }) {
  const url = `/fragments/${id}/`;

  const data = {
    name: title,
  };
  if (Array.isArray(tags)) {
    data.tags = tags;
  }
  const res = await Vue.prototype.$api.patch(url, {
    data,
  });
  dispatch("getProject", id);
}

export default {
  createProject,
  getProject,
  getProjects,
  updateProject,
  renameProject,
  deleteProject,
};
