<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      v-if="$store.state.isAuth"
      dense
      class="d-print-none"
    >
      <div class="d-flex align-center">
        <v-btn to="/" text small>
          <!-- <span class="mr-2">ILUKS</span> -->
          <v-img src="@/assets/iluks_logo_white_button.png" />
        </v-btn>
        <v-btn
          @click="$i18n.locale = 'en'"
          v-if="$i18n.locale === 'no'"
          small
          text
          >English</v-btn
        >
        <v-btn
          @click="$i18n.locale = 'no'"
          v-if="$i18n.locale === 'en'"
          small
          text
          >Norsk</v-btn
        >
      </div>

      <v-spacer></v-spacer>

      <v-btn text small v-if="isAdmin" :to="{ name: 'Admin' }">admin</v-btn>

      <v-btn
        v-if="$route.name !== 'Projects' && $route.name !== 'DashboardCollab'"
        text
        small
        :to="{ name: 'Projects' }"
        >{{ $t("learningDesign.myLearningDesigns") }}</v-btn
      >

      <v-btn v-if="isAuth" text small>
        <span class="mr-2">{{ user.name }}</span>
      </v-btn>

      <v-btn
        v-if="isAuth"
        :href="`${API_BASE_URL}/authenticate/logout`"
        text
        small
      >
        <span class="mr-2">Logout</span>
        <v-icon dense>mdi-logout</v-icon>
      </v-btn>
      <v-btn
        v-if="isAuth === false"
        :href="`${API_BASE_URL}/login?app_home_overide=https://app.iluks.local.dev`"
        text
        small
      >
        <span class="mr-2">Login</span>
        <v-icon dense>mdi-login</v-icon>
      </v-btn>
    </v-app-bar>

    <v-footer app :color="footerColor" dark v-if="showFooter">
      <v-card
        flat
        tile
        width="100%"
        class="transparent text-center ma-0 pa-0 pt-1"
      >
        <v-card-text class="pa-0 ma-0">
          <img
            height="26p"
            width="67"
            src="@/assets/slate_logo.png"
            class="ml-4 mr-4"
          />
          <img
            height="26p"
            width="26"
            src="@/assets/uib_logo.png"
            class="ml-4 mr-4"
          />
        </v-card-text>
      </v-card>

      <v-btn
        color="blue"
        fab
        dark
        small
        absolute
        top
        left
        v-if="$store.state.isAuth && $fg.SLACK_INTEGRATION"
        @click="dialogMessage = !dialogMessage"
      >
        <v-icon>mdi-email</v-icon>
      </v-btn>
    </v-footer>

    <v-main>
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
      <!--
      <transition name="fade">
        <div
          class="chat-button prevent-select"
          @click="showChat"
          v-show="isChat && !chatBotVisible"
        >
          <div>ILUKS<br />chatbot</div>
        </div>
      </transition> -->
    </v-main>

    <v-dialog
      v-model="dialogMessage"
      max-width="500"
      v-if="$fg.SLACK_INTEGRATION"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t("messageToAdministrator.title") }}
        </v-card-title>

        <v-card-text>
          <v-textarea
            v-model="message"
            :placeholder="$t('messageToAdministrator.placeholder')"
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="sendMessage()"
            :disabled="message.length < 12"
          >
            {{ $t("messageToAdministrator.send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConsent" max-width="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Samtykke til bruk av aktivitetsdata fra ILUKS planleggeren
        </v-card-title>

        <v-card-text>
          <p></p>
          Ved å svare ja til dette tillater du at data om din bruk av ILUKS
          planleggeren brukes i læringsanalyse forskning.
        </v-card-text>
        <v-card-text>
          For å forstå hvordan lærerstudenter og lærere arbeider med å designe
          undervisning og hvordan ILUKS-Planleggeren bedre kan støtte disse
          arbeidsprosessene ønsker vi å bruke aktivitetsdata fra ILUKS
          planleggeren til læringsanalyse forskning. Dette er data fra din bruk
          av ILUKS planleggeren og knytter seg til pålogging, opprettelse av
          undervisningsdesign, antall revisjoner, og sammensetning av design.
          For å gi deg tilgang til ILUKS planleggeren så vet vi ditt navn, og
          epost adresse og FEIDE id for pålogging. Denne informasjonen vil
          anonymiseres. Alle data anonymiseres før analyser og vil behandles i
          tråd med personvernregelverket.
        </v-card-text>
        <v-card-text>
          Tillater du at data om din bruk av ILUKS planleggeren brukes i
          læringsanalyse forskning?
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="setConsent(true)"> Ja </v-btn>
          <v-spacer />
          <v-btn color="error" text @click="setConsent(false)"> Nei </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSurvey" max-width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Evaluering </v-card-title>

        <v-card-text>
          <p></p>
          <b
            >Opplevde du at planen hjalp deg i undervisningen du gjennomførte i
            praksisperioden?</b
          >
          <v-radio-group dense v-model="survey.resultFirst">
            <v-radio dense label="I stor grad" value="I stor grad"></v-radio>
            <v-radio
              dense
              label="I middels grad"
              value="I middels grad"
            ></v-radio>
            <v-radio dense label="I liten grad" value="I liten grad"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-text>
          <b>Hvor lang undervisningserfaring har du?</b>
          <v-radio-group dense v-model="survey.resultSecond">
            <v-radio dense label="0-1 år" value="1-2 år"></v-radio>
            <v-radio dense label="2-3 år" value="3-5 år"></v-radio>
            <v-radio dense label="4-5 år" value="6-10 år"></v-radio>
            <v-radio dense label="Mer enn 5 år" value="Mer enn 5 år"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="!(survey.resultFirst && survey.resultSecond)"
            text
            @click="submitSurvey()"
          >
            Send inn
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ChatBot v-if="isAuth" />
  </v-app>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import ChatBot from "@/components/ChatBot.vue";

export default {
  name: "App",
  components: {
    ChatBot,
  },
  data: () => ({
    message: "", // "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    dialogMessage: false,
    dialogConsent: false,
    dialogSurvey: false,
    dialogAi: false,
    survey: {
      resultFirst: false,
      resultSecond: false,
    },
    isChat: false,
    chatBotVisible: false,
  }),
  async mounted() {
    window.$i18n = this.$i18n;
    this.isChat = true;
    // this.$store.dispatch("checkAuth");
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["isAuth", "user", "invites", "appIsLoaded", "isAdmin"]),
    footerColor() {
      if (this.$route.name === "Home") return "purple darken-2";
      return "primary";
    },
    showFooter() {
      // if (this.$route)
      return ["Home", "Projects"].indexOf(this.$route.name) !== -1;
    },
  },
  watch: {
    "$i18n.locale": (val) => {
      localStorage.setItem("lang", val);
    },
    async isAuth(val) {
      if (val && this?.user?.id) {
        var _paq = (window._paq = window._paq || []);
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(["setUserId", `${this.user.id}`]);
        _paq.push(["trackPageView"]);
        _paq.push(["enableLinkTracking"]);
        (function () {
          var u = "https://analytics.slateresearch.ai/";
          _paq.push(["setTrackerUrl", u + "matomo.php"]);
          _paq.push(["setSiteId", "1"]);
          var d = document,
            g = d.createElement("script"),
            s = d.getElementsByTagName("script")[0];
          g.async = true;
          g.src = u + "matomo.js";
          s.parentNode.insertBefore(g, s);
        })();
      }
    },
  },
  methods: {
    async showChat() {
      this.chatBotVisible = !this.chatBotVisible;
    },
    async closeChat() {
      this.chatBotVisible = false;
    },
    async setConsent(val) {
      this.dialogConsent = false;
      if (val) {
        await this.$api.post("/users/consent/analytics/accept");
      } else {
        await this.$api.post("/users/consent/analytics/reject");
      }
      setTimeout(this.checkSurvey, 100);
    },
    async checkSurvey() {
      /**
      if (
        new Date("2023-11-23 00:00").getTime() > new Date().getTime() &&
        this.user.roles.indexOf("user_administrator") < 0 &&
        this.user.roles.indexOf("course_owner") < 0
      )
        return; **/
      try {
        const res = (
          (await this.$api.get("/forms/@me"))?.data?.result || []
        ).filter((e) => e?.formName.indexOf("survey_2023-11-23") === 0);
        if (res.length === 0) {
          this.dialogSurvey = true;
        }
      } catch {
        //
      }
    },
    async submitSurvey() {
      await this.$api.post("/forms/@me", {
        formName: "survey_2023-11-23",
        data: this.survey,
      });
      this.dialogSurvey = false;
    },
    sendMessage() {
      this.dialogMessage = false;
      this.$api
        .post("/contact/send", {
          message: this.message,
        })
        .then((res) => {})
        .catch((error) => {});
    },
  },
};
</script>

<style>
body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>

<!--
<style>
@media print {
  @page {
    size: auto; /* auto is the initial value */
    margin: 0; /* this affects the margin in the printer settings */
  }
}
</style>
-->
<!--
<style lang="css">
@import "~medium-editor/dist/css/medium-editor.css";
@import "~vuejs-medium-editor/src/themes/default.css";
/*@import '~highlight.js/styles/github.css';*/
@import "~highlight.js/styles/ocean.css";
</style>
-->
