export default {
  en: {
    roles: {
      editor: "Editor",
      viewer: "Viewer",
      commenter: "Commenter",
      owner: "Owner",
    },
    buttons: {
      addNewElement: "Add new element",
      addNewCard: "Add card",
      acceptDelete: "Delete",
      rejectDelete: "Cancel",
      confirm: "Confirm",
      decline: "Decline",
    },
    status: {
      status: "Status",
      pending: "Pending",
      accepted: "Accepted",
      declined: "Declined",
    },
    card: {
      titlePlaceHolder: "Enter card name...",
      cardElementName: "Card",
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
    },
    invites: {
      actions: "Actions",
      title: "Invites",
      myInvitations: "My invitations",
      myCollabs: "My collaborations",
      send: "Send",
      sent: "Sent",
      recipient: "Recipient",
      document: "Document",
      role: "Role",
      received: "Received",
      from: "From",
      accept: "Accept",
      reject: "Reject",
      delete: "Delete",
      coworkers: "Colleagues",
      owner: "Owner",
      myRoles: "My roles",
      started: "Started",
      mySelf: "I'm",
      coworkerRoles: "Colleague roles",
      tags: "Tags",
      revokeAccess: "Revoke access",
      leave: "Leave",
      deleteDocument: "Delete document",
      created: "Created",
      modified: "Modified",
    },
    messageToAdministrator: {
      title: "Message to administrator",
      placeholder: "Enter a message text of at least 12 letters",
      send: "Send",
    },
    courses: {
      myCourses: "My courses",
      newCourse: "New course",
      courseName: "Course name",
      startDay: "Start day",
      endDay: "End day",
      students: "Students",
      teachers: "Teachers",
      supervisors: "Supervisors",
      refresh: "Refresh",
      cancel: "Cancel",
      containerLimit: "Limit of designs per student",
    },
    learningDesign: {
      myLearningDesigns: "My learning designs",
      sharedLearningDesigns: "Shared<br />learning designs",
      allOtherLearningDesigns: "All other<br /> learning designs",
      newLearningDesign: "New learning design",
      viewDocument: "View complete learning design",
      viewTable: "Table display",
      hideComments: "Click to hide comments",
      showComments: "Click to show comments",
      hideTimeline: "Click to hide update history",
      showTimeline: "Click to show update history",
      sortCommentsAsc: "Click to show new comments last",
      sortCommentsDesc: "Click to show new comments first",
      participants: "Participants",
      deleteParticipant: "Do you want to delete a participant?",
      newParticipantEmail: "New participant's email",
      newParticipantOrEmail: "New participant or email",
      inviteSupervisor: "Invite supervisor",
      inviteTeacher: "Invite teacher",
      inviteViewer: "Invite viewer",
      inviteEditor: "Invite editor",
      inviteCommenter: "Invite commenter",
      feedback: "Feedback on learning design",
      leaveComment: "Leave a comment",
      downloadLogsFile: "Download logs",
    },
    columns: {
      "teaching-design": {
        title: "Learning design",
        tooltips: [
          "Compose a learning design by dragging and dropping cards from the lists",
        ],
        cardTooltips: [
          "Add subjects and describe the academic content the students will acquire",
        ],
        newCard: "",
      },
      kompetansemaal: {
        title: "Learning goals",
        tooltips: [
          "Describe the learning goals and/or the skills students should achieve",
        ],
        newCard: "Add learning goal",
      },
      trinn: {
        title: "Student prerequisites",
        tooltips: [
          "Describe year level, study program and student prerequisites",
        ],
        newCard: "Add student prerequisites",
      },
      fag: {
        title: "Content",
        tooltips: [
          "Add the subject and describe the academic content the students will acquire",
        ],
        newCard: "Add content",
      },
      laremidler: {
        title: "Learning resources and equipment",
        tooltips: [
          "Describe the learning resources you will use in the teaching",
        ],
        newCard: "Add learning resource",
      },
      vurdeging: {
        title: "Assessment",
        tooltips: [
          "Describe forms of assessment and how the assessment activity is to be carried out",
        ],
        newCard: "Add assessment",
      },
      adrbeidsmater: {
        title: "Activities",
        tooltips: [
          "Describe the activities you will carry out in the lesson",
          "Enter the time spent on each activity",
        ],
        newCard: "Add activity",
      },
    },
  },
  no: {
    roles: {
      editor: "Redigere",
      viewer: "Lesetilgang",
      commenter: "Kommentere",
      owner: "Eier",
    },
    buttons: {
      addNewElement: "Lag et nytt element",
      addNewCard: "Lag et nytt kort",
      acceptDelete: "Godta",
      rejectDelete: "Avbryt",
      confirm: "OK",
      decline: "Avvis",
    },
    status: {
      status: "Status",
      pending: "Venter",
      accepted: "Godtatt",
      declined: "Avslått",
    },
    card: {
      titlePlaceHolder: "Skriv inn navnet ditt...",
      cardElementName: "Kort",
      save: "Lagre",
      cancel: "Avbryt",
      delete: "Slett",
    },
    invites: {
      actions: "Handlinger",
      title: "Inviterer",
      myInvitations: "Invitasjonene mine",
      myCollabs: "Mine samarbeid",
      send: "Send",
      sent: "Sendt",
      recipient: "Mottaker",
      document: "Dokument",
      role: "Rolle",
      received: "Mottatt",
      from: "Fra",
      accept: "Godta",
      reject: "Avvis",
      delete: "Slett",
      coworkers: "Kolleger",
      owner: "Eier",
      myRoles: "Mine roller",
      started: "Startet",
      mySelf: "Jeg",
      coworkerRoles: "Kollega roller",
      tags: "Tags",
      revokeAccess: "Oppheve tilgang",
      leave: "Forlate",
      deleteDocument: "Slett dokument",
      created: "Opprettet",
      modified: "Endret",
    },
    messageToAdministrator: {
      title: "Melding til administrator",
      placeholder: "Skriv inn en meldingstekst på minst 12 bokstaver",
      send: "Sende",
    },
    courses: {
      myCourses: "Mine kurs",
      newCourse: "Nytt course",
      courseName: "Kursnavn",
      startDay: "Startdato",
      endDay: "Sluttdato",
      students: "Studenter",
      teachers: "Lærere",
      supervisors: "Veiledere",
      refresh: "Forfriske",
      cancel: "Avbryt",
      containerLimit: "Begrensning av design per student",
    },
    learningDesign: {
      myLearningDesigns: "Mine undervisningsdesign",
      sharedLearningDesigns: "Delte<br />undervisningsdesign",
      allOtherLearningDesigns: "Alle andre<br />undervisningsdesign",
      newLearningDesign: "Nytt undervisningsdesign",
      viewDocument: "Dokumentvisning",
      viewTable: "Tabellvisning",
      hideComments: "Trykk for å skjule kommentarer",
      showComments: "Trykk for å vise kommentarer",
      hideTimeline: "Trykk for å skjule oppdateringshistorikk",
      showTimeline: "Trykk for å vise oppdateringshistorikk",
      sortCommentsAsc: "Klikk for å vise nye kommentarer sist",
      sortCommentsDesc: "Klikk for å vise nye kommentarer først",
      participants: "Deltakere",
      deleteParticipant: "Vil du slette en deltaker?",
      newParticipantEmail: "Ny deltakers e-post",
      newParticipantOrEmail: "Ny deltakers elle e-post",
      inviteSupervisor: "Inviter veileder",
      inviteTeacher: "Inviter en lærer",
      inviteViewer: "Inviter seeren",
      inviteEditor: "Inviter redaktøren",
      inviteCommenter: "Inviter kommentator",
      feedback: "Tilbakemelding undervisningsdesign",
      leaveComment: "Legg igjen kommentarer",
      downloadLogsFile: "Last ned endringslogg",
    },
    columns: {
      "teaching-design": {
        title: "Undervisningsdesign",
        tooltips: [
          "Sett sammen undervisningsdesignet ved å dra og slippe kort fra listene",
        ],
        cardTooltips: [
          "Sett inn fag og beskriv det faglige innholdet elevene skal tilegne seg",
        ],
        newCard: "",
      },
      kompetansemaal: {
        title: "Læringsmål",
        tooltips: [
          "Beskriv kompetansemål og/eller ferdigheter som elevene skal oppnå etter undervisningen",
        ],
        newCard: "Legg til kompetansemål",
      },
      trinn: {
        title: "Elevforutsetninger",
        tooltips: [
          "Beskriv årstrinn, studieprogram og elevgruppens forutsetninger",
        ],
        newCard: "Legg til elevforutsetning",
      },
      fag: {
        title: "Innhold",
        tooltips: [
          "Sett inn fag og beskriv det faglige innholdet elevene skal tilegne seg",
        ],
        newCard: "Legg til innhold",
      },
      laremidler: {
        title: "Læringsressurser og utstyr",
        tooltips: ["Beskriv læremidlene du vil benytte i undervisningen"],
        newCard: "Legg til læremiddel",
      },
      vurdeging: {
        title: "Vurdering",
        tooltips: [
          "Beskriv vurderingsformer og hvordan vurderingsaktiviteten skal gjennomføres",
        ],
        newCard: "Legg til vurderingsform",
      },
      adrbeidsmater: {
        title: "Aktiviteter",
        tooltips: [
          "Beskriv aktivitetene du vil gjennomføre i undervisningen",
          "Angi tidsbruk for hver aktivitet",
        ],
        newCard: "Legg til arbeidsmåte",
      },
    },
  },
};
